import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getTenantId } from 'utils/tenantUtil';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { CustomTableCell } from './CustomTableCell';
import { TableCell, TableRow } from '@material-ui/core';
import { BasicTable } from 'components/DebFormComponents/Table/BasicTable';
import { FormSection } from 'components/FormHelper/FormSection';
import { NE_ERROR } from 'components/NEForm/Utils/NEError';
import { isRegisteringInstallerGuestOnly } from 'utils/InstallerUtils';
import { isInstallerRegisteringForBranch, isRegisteringBranchGuest } from 'utils/InstallerUtils';
import { BRANCH_TYPE, TENANT_ID } from 'global/constants';
import './NEExpertsTable.sass';
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip';
const NEExpertTable = ({ formik, branchSelectionValues, errors }) => {
  const deleteExpert = (id) => {
    const newExperts = formik.values.experts.filter((expert) => expert.id !== id);
    formik.setFieldValue('experts', newExperts);
  };

  const headers = () => {
    return [
      <TableCell component="th" align="left" scope="row" key={'firstName'}>
        <div className="ne-expert-table-cell__horizontal-flex">Nachname</div>
      </TableCell>,
      <TableCell align="left" key={'lastName'}>
        <div className="ne-expert-table-cell__horizontal-flex">Vorname</div>
      </TableCell>,
      ...(getTenantId() === TENANT_ID.BAG
        ? [
            <TableCell align="left" key={'birthdate'}>
              <div className="ne-expert-table-cell__horizontal-flex">Geburtstag</div>
            </TableCell>,
          ]
        : []),
      ...(branchSelectionValues.isEnergyActive
        ? [
            <TableCell align="left" key={'isExpertEnergyActive'}>
              <div className="ne-expert-table-cell__horizontal-flex">Strom</div>
            </TableCell>,
          ]
        : []),
      ...(branchSelectionValues.isGasActive
        ? [
            <CustomTableCell align="left" key={'isExpertGasActive'}>
              <div className="ne-expert-table-cell__horizontal-flex">Gas</div>
            </CustomTableCell>,
          ]
        : []),
      ...(!isRegisteringInstallerGuestOnly(branchSelectionValues)
        ? [
            <TableCell align="left" key={'isExpertOwner'}>
              <div className="ne-expert-table-cell__horizontal-flex">
                Inhaber
                <InfoTooltip
                  title={'auch Geschäftsführer*in, Gesellschafter*in, Teilhaber*in'}></InfoTooltip>
              </div>
            </TableCell>,
          ]
        : []),
      ...(isInstallerRegisteringForBranch(BRANCH_TYPE.ENERGY, branchSelectionValues) &&
      isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, branchSelectionValues)
        ? [
            <TableCell align="left" key={'energyValidityDate'}>
              <div className="ne-expert-table-cell__horizontal-flex">Ausweis gültig bis Strom</div>
            </TableCell>,
          ]
        : []),
      ...(isInstallerRegisteringForBranch(BRANCH_TYPE.GAS, branchSelectionValues) &&
      isRegisteringBranchGuest(BRANCH_TYPE.GAS, branchSelectionValues)
        ? [
            <TableCell align="left" key={'gasValidityDate'}>
              <div className="ne-expert-table-cell__horizontal-flex">Ausweis gültig bis Gas</div>
            </TableCell>,
          ]
        : []),
      <TableCell align="right" key={'Button'}></TableCell>,
    ];
  };

  const rows = useMemo(() => {
    const rowsNew = formik.values.experts.map((row) => {
      return (
        <TableRow
          key={row.id}
          sx={{
            '&:last-child td, &:last-child th': { border: 0 },
          }}>
          <CustomTableCell scope="row" key={'lastName'}>
            {row.lastName}
          </CustomTableCell>
          <CustomTableCell align="left" scope="row" key={'firstName'}>
            {row.firstName}
          </CustomTableCell>
          {getTenantId() === TENANT_ID.BAG ? (
            <CustomTableCell align="left" key={'birthdate'}>
              {row.birthdate}
            </CustomTableCell>
          ) : null}
          {branchSelectionValues.isEnergyActive ? (
            <CustomTableCell align="left" key={'isExpertEnergyActive'}>
              {row.isExpertEnergyActive}
            </CustomTableCell>
          ) : null}
          {branchSelectionValues.isGasActive ? (
            <CustomTableCell align="left" key={'isExpertGasActive'}>
              {row.isExpertGasActive}
            </CustomTableCell>
          ) : null}
          {!isRegisteringInstallerGuestOnly(branchSelectionValues) ? (
            <CustomTableCell align="left" key={'isExpertOwner'}>
              {row.isExpertOwner}
            </CustomTableCell>
          ) : null}
          {isInstallerRegisteringForBranch(BRANCH_TYPE.ENERGY, branchSelectionValues) &&
          isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, branchSelectionValues) ? (
            <CustomTableCell align="left" key={'energyValidityDate'}>
              {row.energyValidityDate}
            </CustomTableCell>
          ) : null}
          {isInstallerRegisteringForBranch(BRANCH_TYPE.GAS, branchSelectionValues) &&
          isRegisteringBranchGuest(BRANCH_TYPE.GAS, branchSelectionValues) ? (
            <CustomTableCell align="left" key={'gasValidityDate'}>
              {row.gasValidityDate}
            </CustomTableCell>
          ) : null}
          <CustomTableCell align="right" key={'deleteButton'} className="action-buttons">
            <ActionButton title="Entfernen" onClick={() => deleteExpert(row.id)} />
          </CustomTableCell>
        </TableRow>
      );
    });
    return rowsNew;
  }, [formik.values.experts, branchSelectionValues]);

  return (
    <div className="expert-table__wrapper">
      <>
        <FormSection
          infoText={
            <>
              <div className={errors.includes(NE_ERROR.MIN_EXPERT) ? 'ne-form__error-text' : ''}>
                Bitte fügen Sie mindestens eine Fachkraft pro Sparte hinzu.
              </div>
              {branchSelectionValues.isEnergyActive && branchSelectionValues.isGasActive ? (
                <div
                  className={
                    errors.includes(NE_ERROR.MIN_BRANCH_EXPERT) ? 'ne-form__error-text' : ''
                  }>
                  (z.B. Herr Muster für die Sparten Gas und Strom oder Fachkraft 1 für Strom und
                  Fachkraft 2 für Gas)
                </div>
              ) : null}
            </>
          }>
          <BasicTable headers={headers()} rows={rows}></BasicTable>
        </FormSection>
      </>
    </div>
  );
};

NEExpertTable.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.oneOf(Object.values(NE_ERROR))).isRequired,
  formik: PropTypes.object.isRequired,
  branchSelectionValues: PropTypes.object.isRequired,
};

export { NEExpertTable };
