/* eslint-disable react/prop-types */
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import './EventRegisterForm.scss';

import { Utils } from '@adobe/aem-react-editable-components';
import { useFormik } from 'formik';
import { ThemedTextField } from 'components/ThemedTextField/ThemedTextField';
import { YEventRegistration } from './YEventRegistration';
import { EventRegisterFormFieldWrapper } from 'components/Event/EventRegisterForm/EventRegisterFormFieldWrapper';
import { checkKeyDown, FFormDebug } from 'utils/FormUtils';
import { getEventsOverviewPath } from 'utils/UrlUtil';
import { FORM_CONSTANTS, TENANT_ID } from 'global/constants';
import { HintText } from 'components/HintText/HintText';
import { HINTTEXT_TYPE } from 'components/HintText/HintTextType';

import { getTenantId } from 'utils/tenantUtil';
import {
  REMOVE_NEW_TAG_FROM_EVENT_MUTATION,
  SET_PARTICIPANTS_MUTATION,
  UNREGISTER_FROM_EVENT_MUTATION,
} from 'Queries/Mutations';
import { getAllEventsQuery } from 'Queries/Queries';
import isNewEntry from 'components/OverviewLists/utils/isNewEntry';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { CustomModal } from 'components/CustomModal';
import { PageTitleSection } from 'components/PageTitleSection/PageTitleSection';
import OuterBox from 'components/OuterBox/OuterBox';
import { SubTitle } from 'components/SubTitle/SubTitle';
import { Separator } from 'components/Separator/Separator';
import EventMetaData from '../EventMetaData/EventMetaData';
import { formatDate } from 'utils/DatesUtils';

const DEFAULT_NEW_PARTICIPANT = {
  firstName: '',
  lastName: '',
  eMail: '',
};

const EventRegisterForm = ({ event, userStatusData, installer, refetchEvent }) => {
  // props
  const { id: eventId, registration } = event;
  const MAX_PARTICIPANTS = Math.min(event.maxParticipantsPerCompany, event.maxParticipants) || 0;
  const getIsRegistrationExpired = (event) => {
    const currentDate = formatDate(new Date());
    const registrationDeadline = formatDate(event.registrationDeadline);
    const parsedCurrentDate = Date.parse(currentDate.split('.').reverse().join('/'));
    const parsedRegistrationDeadline = Date.parse(
      registrationDeadline.split('.').reverse().join('/')
    );

    return parsedRegistrationDeadline < parsedCurrentDate;
  };
  const hideAddParticipant = getIsRegistrationExpired(event);
  const userCanRegisterToEvent = !hideAddParticipant || registration.participants.length;

  // other
  const isInEditor = Utils.isInEditor();

  // state & hooks
  const tenant = getTenantId();
  const history = useHistory();
  const [remainingAllowed, setRemainingAllowed] = useState(null);
  const [errorFromRequest, setErrorFromRequest] = useState({});

  const [success, setSuccess] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: 'Wirklich absagen?',
    content: 'Willst du den Termin wirklich für dich und alle deine Teilnehmer absagen?',
  });

  // mutations & queries

  const [setParticipantsMutation, { data: setParticipantsResponse }] = useMutation(
    SET_PARTICIPANTS_MUTATION,
    {
      onError({ graphQLErrors, networkError }) {
        if (graphQLErrors) setErrorFromRequest(graphQLErrors[0].extensions);
        if (networkError) console.log(`[Network error]: ${networkError}`);
      },
    }
  );

  const [reloadEvents] = useLazyQuery(getAllEventsQuery);

  const [removeNewTag] = useMutation(REMOVE_NEW_TAG_FROM_EVENT_MUTATION, {
    refetchQueries: () => [{ query: getAllEventsQuery }],
    awaitRefetchQueries: false,
    variables: {
      eventId,
    },
  });

  const [unregisterFromEvent, { data: dataResponseFromUnregisterEvent }] = useMutation(
    UNREGISTER_FROM_EVENT_MUTATION
  );

  // form state
  const onSubmitForm = async (values) => {
    let companyName = values.firmenname1;
    if (values.firmenname2) {
      companyName += ` ${values.firmenname2}`;
    }
    if (tenant === TENANT_ID.BAG && values.firmenname3) {
      companyName += ` ${values.firmenname3}`;
    }

    const variables = {
      eventId,
      participants: values.participants,
      companyName: companyName.trim(),
    };

    await setParticipantsMutation({ variables });
    await refetchEvent();
  };

  const formik = useFormik({
    initialValues: {
      firmenname1: '',
      firmenname2: '',
      firmenname3: '',
      companyName: registration.companyName,
      userId: userStatusData.userStatus.email,
      participants: registration.participants.length
        ? registration.participants.map(({ firstName, lastName, eMail }) => ({
            firstName,
            lastName,
            eMail,
          }))
        : [DEFAULT_NEW_PARTICIPANT],
    },
    validationSchema: YEventRegistration,
    onSubmit: onSubmitForm,
  });

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
    isValid,
    isSubmitting,
  } = formik;

  // effects
  useEffect(() => {
    async function handleRemoveTag() {
      await removeNewTag();
    }

    if (!isInEditor && isNewEntry(event.tags)) {
      // fail silent
      handleRemoveTag();
    }
  }, [event.tags, isInEditor, removeNewTag]);

  useEffect(() => {
    if (
      !setParticipantsResponse &&
      errorFromRequest?.code === 'BAD_USER_INPUT' &&
      errorFromRequest?.data?.action === 'booking' &&
      errorFromRequest?.data?.availability !== 'undefined'
    ) {
      setRemainingAllowed(errorFromRequest.data.availability);
    } else if (setParticipantsResponse?.setParticipants) {
      setModalIsOpen(true);
    }
  }, [setParticipantsResponse, errorFromRequest]);

  useEffect(() => {
    if (!dataResponseFromUnregisterEvent) return;
    const newContent = {
      title: 'Teilnahme abgesagt',
      content: 'Wir haben deine Absage erhalten...',
    };
    setModalContent(newContent);
    setSuccess(true);
  }, [dataResponseFromUnregisterEvent]);

  useEffect(() => {
    if (userStatusData.userStatus.iamType === 'CIAM') {
      if (installer.installer.firmenname1) {
        setFieldValue('firmenname1', installer.installer.firmenname1);
      }
      if (installer.installer.firmenname2) {
        setFieldValue('firmenname2', installer.installer.firmenname2);
      }
      if (installer.installer.firmenname3 && tenant === TENANT_ID.BAG) {
        setFieldValue('firmenname3', installer.installer.firmenname3);
      }
    } else if (userStatusData.userStatus.iamType === 'EIAM') {
      setFieldValue('firmenname1', 'Interner Mitarbeiter');
    }
  }, [installer, userStatusData]);

  // functions
  const closeModal = () => {
    reloadEvents();
    setModalIsOpen(false);
    history.push(getEventsOverviewPath());
  };

  const removeParticipant = (event, index) => {
    event.preventDefault();
    const newInstallers = values.participants.filter((participant, iindex) => iindex !== index);
    setFieldValue('participants', newInstallers);
  };

  const participantIsRemoveable = (participant) => {
    const hasMoreThanOneParticipant = values.participants.length > 1;

    if (!participant.id) {
      if (hasMoreThanOneParticipant) return true;
      return false;
    }

    const prefilledParticipants = values.participants.filter((participant) =>
      // eslint-disable-next-line no-prototype-builtins
      participant.hasOwnProperty('id')
    );

    if (prefilledParticipants && prefilledParticipants.length === 1) {
      return false;
    }

    return true;
  };

  const handleCancelEventClick = async () => {
    const variables = { eventId: event.id, unregister: true, participants: [] };
    await unregisterFromEvent({ variables });
  };

  const getModalCtaButtons = () => {
    if (success) {
      return <ActionButton onClick={() => closeModal()} title="Ok, verstanden" inverted />;
    }

    return (
      <>
        <ActionButton onClick={handleCancelEventClick} title="Absagen" inverted />
        <ActionButton onClick={() => setIsOpen(false)} title="Abbrechen" inverted />
      </>
    );
  };

  const addNewInput = (event) => {
    event.preventDefault();
    const newParticipants = [...values.participants];
    newParticipants.push({ ...DEFAULT_NEW_PARTICIPANT });
    setFieldValue('participants', newParticipants);
  };

  return (
    <>
      <CustomModal
        title={
          registration.participants.length
            ? 'Ihre Änderung war erfolgreich!'
            : 'Ihre Anmeldung war erfolgreich.'
        }
        content={
          <div className="event-register-form__modal-content-wrapper">
            {registration.participants.length
              ? ''
              : 'Sie erhalten in Kürze per E-Mail eine Bestätigung mit allen Veranstaltungsinformationen.'}
          </div>
        }
        ctaButtons={<ActionButton title="Schließen" onClick={closeModal} inverted />}
        isOpen={modalIsOpen}
      />
      <CustomModal
        title={modalContent.title}
        content={
          <div className="event-register-form__modal-content-wrapper">{modalContent.content}</div>
        }
        ctaButtons={getModalCtaButtons()}
        isOpen={isOpen}
      />
      <PageTitleSection
        title="Anmeldung"
        subText={
          'Hier können Sie sich für die von Ihnen ausgewählte Veranstaltung anmelden. Sie können den Termin anschließend nach erfolgreicher Anmeldung auf Ihrer e-fix Startseite sehen und jeder Zeit wieder absagen oder die Teilnehmer ändern.'
        }
        notFramed
      />
      <OuterBox>
        <SubTitle text="Ausgewählte Veranstaltung" notFramed />
        <Separator color={getTenantId() === TENANT_ID.AVA ? 'homepage' : 'secondary'} />
        <EventMetaData event={event} />
        {userCanRegisterToEvent ? (
          <form className="event-register-form" onSubmit={handleSubmit} onKeyDown={checkKeyDown}>
            <SubTitle text="Anmeldung:" notFramed />
            <div className="event-register-form__registration-wrapper">
              <div className="event-register-form__participant-column-wrapper">
                <EventRegisterFormFieldWrapper>
                  <ThemedTextField
                    fullWidth
                    disabled
                    id={`firmenname1`}
                    name={`firmenname1`}
                    label="Firmenname1*"
                    variant="standard"
                    value={values.firmenname1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.firmenname1 && Boolean(errors.firmenname1)}
                    helperText={touched.firmenname1 && errors.firmenname1}
                  />
                </EventRegisterFormFieldWrapper>
                {userStatusData.userStatus.iamType !== 'EIAM' && (
                  <EventRegisterFormFieldWrapper>
                    <ThemedTextField
                      fullWidth
                      disabled
                      id={`firmenname2`}
                      name={`firmenname2`}
                      label="Firmenname2"
                      variant="standard"
                      value={values.firmenname2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.firmenname2 && Boolean(errors.firmenname2)}
                      helperText={touched.firmenname2 && errors.firmenname2}
                    />
                  </EventRegisterFormFieldWrapper>
                )}
                {tenant === TENANT_ID.BAG && userStatusData.userStatus.iamType !== 'EIAM' && (
                  <EventRegisterFormFieldWrapper>
                    <ThemedTextField
                      fullWidth
                      disabled
                      id={`firmenname3`}
                      name={`firmenname3`}
                      label="Firmenname3"
                      variant="standard"
                      value={values.firmenname3}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.firmenname3 && Boolean(errors.firmenname3)}
                      helperText={touched.firmenname3 && errors.firmenname3}
                    />
                  </EventRegisterFormFieldWrapper>
                )}
              </div>
              <div className="event-register-form__participant-column-wrapper">
                <EventRegisterFormFieldWrapper>
                  <ThemedTextField
                    fullWidth
                    disabled
                    id={`userId`}
                    name={`userId`}
                    label="E-Mail für Informationsversand*"
                    variant="standard"
                    value={values.userId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.userId && Boolean(errors.userId)}
                    helperText={touched.userId && errors.userId}
                  />
                  <div className="event-register-form__email-information">
                    Tragen Sie E-Mail-Adressen der Fachkräfte ein, damit die Veranstaltungseinladung
                    und ggf. Veranstaltungsinformationen an diese gesendet werden
                  </div>
                </EventRegisterFormFieldWrapper>
              </div>
            </div>
            <div className="event-register-form__participant-title-wrapper">
              <SubTitle
                text={`Teilnehmer (max ${MAX_PARTICIPANTS}):`}
                className="event-register-form__participation-title"
                notFramed
              />
              {registration.participants.length > 0 && (
                <div
                  className="event-register-form__participant-cancel"
                  onClick={() => setIsOpen(true)}>
                  Teilnahme absagen
                </div>
              )}
            </div>
            {event.tags.includes(FORM_CONSTANTS.TAGS_EVENTS.KOSTENPFLICHTIG) && (
              <div className="event-register-form__charging-amount">
                Anmeldegebühr je Teilnehmer: {event.chargingAmount.replace('.', ',')}€
              </div>
            )}
            {values.participants.map((participant, index) => {
              return (
                <div key={`participant-${index}`}>
                  <div>
                    <div className="event-register-form-new-participant-meta-container">
                      <div className="event-register-form__position">{index + 1}</div>
                      <div className="event-register-form__seperator" />
                      <div className="event-register-form__remove-participant-btn-container">
                        {participantIsRemoveable(participant) ? (
                          <button
                            className="event-register-form__remove-participant-btn"
                            onClick={(e) => removeParticipant(e, index)}>
                            Teilnehmer entfernen
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="event-register-form-new-participant-fields">
                    <div className="event-register-form__registration-wrapper">
                      <div className="event-register-form__participant-column-wrapper">
                        <EventRegisterFormFieldWrapper>
                          <ThemedTextField
                            fullWidth
                            disabled={!userCanRegisterToEvent}
                            id={`participants.${index}.firstName`}
                            name={`participants.${index}.firstName`}
                            label="Vorname*"
                            variant="standard"
                            value={values.participants[index].firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.participants &&
                              touched.participants[index] &&
                              touched.participants[index].firstName &&
                              errors.participants &&
                              errors.participants[index] &&
                              Boolean(errors.participants[index].firstName)
                            }
                            helperText={
                              touched.participants &&
                              touched.participants[index] &&
                              touched.participants[index].firstName &&
                              errors.participants &&
                              errors.participants[index] &&
                              errors.participants[index].firstName
                            }
                          />
                        </EventRegisterFormFieldWrapper>
                        <EventRegisterFormFieldWrapper>
                          <ThemedTextField
                            fullWidth
                            disabled={!userCanRegisterToEvent}
                            id={`participants.${index}.lastName`}
                            name={`participants.${index}.lastName`}
                            label="Nachname*"
                            variant="standard"
                            value={values.participants[index].lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.participants &&
                              touched.participants[index] &&
                              touched.participants[index].lastName &&
                              errors.participants &&
                              errors.participants[index] &&
                              Boolean(errors.participants[index].lastName)
                            }
                            helperText={
                              touched.participants &&
                              touched.participants[index] &&
                              touched.participants[index].lastName &&
                              errors.participants &&
                              errors.participants[index] &&
                              errors.participants[index].lastName
                            }
                          />
                        </EventRegisterFormFieldWrapper>
                      </div>
                      <div className="event-register-form__participant-column-wrapper">
                        <EventRegisterFormFieldWrapper>
                          <ThemedTextField
                            fullWidth
                            disabled={!userCanRegisterToEvent}
                            id={`participants.${index}.eMail`}
                            name={`participants.${index}.eMail`}
                            label="Optionale E-Mail"
                            variant="standard"
                            value={values.participants[index].eMail}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.participants &&
                              touched.participants[index] &&
                              touched.participants[index].eMail &&
                              errors.participants &&
                              errors.participants[index] &&
                              Boolean(errors.participants[index].eMail)
                            }
                            helperText={
                              touched.participants &&
                              touched.participants[index] &&
                              touched.participants[index].eMail &&
                              errors.participants &&
                              errors.participants[index] &&
                              errors.participants[index].eMail
                            }
                          />
                        </EventRegisterFormFieldWrapper>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {values.participants.length < MAX_PARTICIPANTS && !hideAddParticipant ? (
              <button
                className="event-register-form__add-participant-btn"
                onClick={addNewInput}
                type="button">
                <span className="event-register-form__add-participant-btn-icon">+</span>
                Teilnehmer hinzufügen
              </button>
            ) : null}
            <div className="event-register-form__submit-button-wrapper">
              {userCanRegisterToEvent ? (
                <ActionButton
                  title={registration.participants.length ? 'Speichern' : 'Anmelden'}
                  disabled={!isValid || isSubmitting}
                  inverted
                />
              ) : (
                <></>
              )}
            </div>

            {remainingAllowed && (
              <div className="event-register-form__booked-out-text">
                <HintText type={HINTTEXT_TYPE.ERROR}>
                  {remainingAllowed === 0
                    ? 'Ups, da war wohl jemand Anderes schneller. Die Veranstaltung ist leider ausgebucht.'
                    : `Ups, da war wohl jemand Anderes schneller. Die restliche freie Teilnehmerzahl  beträgt leider nur noch ${remainingAllowed} Teilnehmer. Bitte reduzieren Sie Ihre Teilnehmeranzahl entsprechend.`}
                </HintText>
              </div>
            )}
            <div className="event-register-form__mandantory-fields-description">
              Mit * gekennzeichnete Felder sind Pflichtfelder
            </div>
            <FFormDebug values={values} errors={errors} touched={touched} />
          </form>
        ) : (
          <div>Registrierung für dieses Event ist geschlossen.</div>
        )}
      </OuterBox>
    </>
  );
};

export default EventRegisterForm;
