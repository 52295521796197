import React from 'react';
import './NEBranchInformation.sass';
import { InputRow } from 'components/FormHelper/InputRow';
import { LabelRadiobutton } from 'components/LabelRadiobutton/LabelRadiobutton';
import PropTypes from 'prop-types';
import { branchToCapital } from 'utils/DataUtils';
import { SectionSubtitle } from 'components/FormHelper/SectionSubtitle';
import { BRANCH_TYPE_TO_GERMAN_NAME, EIV_ENTRY_TYPE } from 'global/constants';

const NEBranchInformation = ({ formik, branch }) => {
  return (
    <div className="branch-selection-information">
      <SectionSubtitle>{BRANCH_TYPE_TO_GERMAN_NAME[branch]}</SectionSubtitle>

      <div className="ne-step-branch-selection">
        <div>Bitte wählen Sie die Art der Eintragung:</div>
        <InputRow>
          <LabelRadiobutton
            label="Stammeintrag"
            name={`branchType${branchToCapital(branch)}`}
            id={`branchType${branchToCapital(branch)}`}
            error={Boolean(formik.errors[`branchType${branchToCapital(branch)}`])}
            value={EIV_ENTRY_TYPE.MAIN}
            checked={formik.values[`branchType${branchToCapital(branch)}`] === EIV_ENTRY_TYPE.MAIN}
            onChange={formik.handleChange}></LabelRadiobutton>
          <LabelRadiobutton
            label="Gasteintrag"
            name={`branchType${branchToCapital(branch)}`}
            id={`branchType${branchToCapital(branch)}`}
            error={Boolean(formik.errors[`branchType${branchToCapital(branch)}`])}
            value={EIV_ENTRY_TYPE.GUEST}
            checked={formik.values[`branchType${branchToCapital(branch)}`] === EIV_ENTRY_TYPE.GUEST}
            onChange={formik.handleChange}></LabelRadiobutton>
        </InputRow>
        {formik.values[`branchType${branchToCapital(branch)}`] === EIV_ENTRY_TYPE.MAIN ? (
          <div className="NE-info-box">
            Diese Auswahl bedeutet, Ihr Firmensitz liegt in unserem Netzgebiet.
          </div>
        ) : formik.values[`branchType${branchToCapital(branch)}`] !== '' ? (
          <div className="NE-info-box">
            Diese Auswahl bedeutet, Ihr Firmensitz liegt <b>nicht</b> in unserem Netzgebiet und Sie
            haben eine Eintragung bei Ihrem zuständigen Netzbetreiber.
          </div>
        ) : (
          <></>
        )}
        Bitte wählen Sie die Betriebsart aus:
        <InputRow>
          <LabelRadiobutton
            label="Hauptbetrieb"
            tooltipText="Ein handwerklicher Hauptbetrieb liegt vor, wenn dieses Handwerk von einer natürlichen oder juristischen Person oder Personengesellschaft als stehende Gewerke selbstständig ausgeübt wird und der Inhaber bzw. verantwortliche Leiter des Betriebes in die Handwerksrolle eingetragen ist."
            name={`companyType${branchToCapital(branch)}`}
            id={`companyType${branchToCapital(branch)}`}
            error={Boolean(formik.errors[`companyType${branchToCapital(branch)}`])}
            checked={formik.values[`companyType${branchToCapital(branch)}`] === 'Hauptbetrieb'}
            value="Hauptbetrieb"
            onChange={formik.handleChange}></LabelRadiobutton>
          <LabelRadiobutton
            label="Nebenbetrieb"
            tooltipText="Ein handwerklicher Nebenbetrieb setzt immer einen Hauptbetrieb voraus. Haupt- und Nebenbetrieb müssen wirtschaftlich miteinander verbunden sein, wobei der Nebenbetrieb im Rahmen des Gesamtunternehmens von untergeordneter wirtschaftlicher Bedeutung ist. Im handwerklichen Nebenbetrieb werden Waren zum Absatz an Dritte handwerksmäßig hergestellt oder Leistungen für Dritte handwerkmäßig bewirkt. Kein Nebenbetrieb liegt vor, wenn eine solche Tätigkeit nur in unerheblichem Umfang ausgeübt wird oder es sich um einen Hilfsbetrieb handelt."
            name={`companyType${branchToCapital(branch)}`}
            id={`companyType${branchToCapital(branch)}`}
            error={Boolean(formik.errors[`companyType${branchToCapital(branch)}`])}
            checked={formik.values[`companyType${branchToCapital(branch)}`] === 'Nebenbetrieb'}
            value="Nebenbetrieb"
            onChange={formik.handleChange}></LabelRadiobutton>
          <LabelRadiobutton
            label="Hilfsbetrieb"
            tooltipText="Hilfsbetriebe sind unselbstständige, der wirtschaftlichen Zweckbestimmung des Hauptbetriebes dienende Handwerksbetriebe, die nicht in die Handwerksrolle eingetragen werden (ausführliche Erklärung in §3 HwO)."
            name={`companyType${branchToCapital(branch)}`}
            id={`companyType${branchToCapital(branch)}`}
            error={Boolean(formik.errors[`companyType${branchToCapital(branch)}`])}
            checked={formik.values[`companyType${branchToCapital(branch)}`] === 'Hilfsbetrieb'}
            value="Hilfsbetrieb"
            onChange={formik.handleChange}></LabelRadiobutton>
        </InputRow>
      </div>
    </div>
  );
};
NEBranchInformation.propTypes = {
  formik: PropTypes.object,
  branch: PropTypes.string,
};
export { NEBranchInformation };
