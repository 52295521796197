import { isLocal } from 'utils/StageUtils';
import { avaconGuestBothFormData, avaconGuestBothInstallerData } from '../Testing/Avacon/GuestBoth';
import {
  bagEnergyMainFormData,
  bagEnergyMainInstallerData,
  bagGasMainFormData,
  bagGasMainInstallerData,
} from '../Testing/Bag/EnergyMain';
import { edisEnergyGuestFormData, edisEnergyGuestInstallerData } from '../Testing/Edis/Edis';

import {
  energyMainHilfsbetriebGasGuestHauptbetriebFormData,
  energyMainHilfsbetriebGasGuestHauptbetriebInstallerData,
} from '../Testing/Bag/EnergyMainHilfsbetriebGasGuestHauptbetrieb';

import {
  shngEnergyGuestGasMainFormData,
  shngEnergyGuestGasMainInstallerData,
} from '../Testing/Shng/EnergyGuestGasMain';
const TEST_SUITES = {
  avaconGuestBoth: {
    formData: avaconGuestBothFormData,
    installerData: avaconGuestBothInstallerData,
  },
  bagGasMain: {
    formData: bagGasMainFormData,
    installerData: bagGasMainInstallerData,
  },
  bagEnergyMain: {
    formData: bagEnergyMainFormData,
    installerData: bagEnergyMainInstallerData,
  },
  bagEnergyMainHilfsbertriebGasGuestHauptbetrieb: {
    formData: energyMainHilfsbetriebGasGuestHauptbetriebFormData,
    installerData: energyMainHilfsbetriebGasGuestHauptbetriebInstallerData,
  },
  edisEnergyGuest: {
    formData: edisEnergyGuestFormData,
    installerData: edisEnergyGuestInstallerData,
  },
  shngEnergyGuestGasMain: {
    formData: shngEnergyGuestGasMainFormData,
    installerData: shngEnergyGuestGasMainInstallerData,
  },
};

const shouldFillTestData = () => {
  return isLocal();
};

// const DEB_CURRENT_TEST_SUITE = TEST_SUITES.bagEnergyMain;
const DEB_CURRENT_TEST_SUITE = TEST_SUITES.bagEnergyMain;

export { DEB_CURRENT_TEST_SUITE, shouldFillTestData };
