/* eslint-disable react/prop-types */
import axios from 'axios';
import React, { useState, createContext, useEffect, useMemo, useContext } from 'react';
import { useHash } from 'react-use';
import { parseAccessToken, isTokenExpired } from '../utils/AuthUtils';
import { getAuthEIAMUrl, getAuthCIAMUrl, getHomepageUrlByTenantId } from 'utils/UrlUtil';
import { setCookie } from 'utils/CookieUtils';
import { useCookie } from 'hooks/useCookie';
import { STORAGE_KEY } from 'global/constants';
import { getTenantId } from 'utils/tenantUtil';

const AuthContext = createContext({});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const Auth = ({ children, code, isCIAM, isPublic }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useCookie(STORAGE_KEY.JWT, '');
  const [hash] = useHash();

  const getAuthUrl = () => {
    if (isCIAM) {
      return getAuthCIAMUrl();
    } else {
      return getAuthEIAMUrl();
    }
  };

  const getJWTToken = async (code) => {
    return axios.post(
      getAuthUrl(),
      JSON.stringify({
        authCode: code,
        tenantId: getTenantId(),
        redirectUrl: isCIAM
          ? getHomepageUrlByTenantId()
          : getHomepageUrlByTenantId() + '?auth=EIAM',
      }),
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
  };

  const handleCode = async (code) => {
    try {
      const tokenEON = await getJWTToken(code);
      if (tokenEON) {
        setToken(tokenEON.data.jwtToken);
      }
    } catch (err) {
      console.log('Error in handleCode', err);
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setCookie(STORAGE_KEY.IAM, isCIAM ? 'CIAM' : 'EIAM', 7);
    const verify = async () => {
      if (isPublic) {
        setIsAuthenticated(true);
        setIsLoading(false);
      } else {
        if (token && !isTokenExpired(token)) {
          setIsAuthenticated(true);
          setIsLoading(false);
        } else {
          if (code) {
            await handleCode(code);
          } else {
            const parsedToken = parseAccessToken(hash);
            if (parsedToken) {
              setToken(parsedToken);
            } else {
              setIsAuthenticated(false);
              setIsLoading(false);
            }
          }
        }
      }
    };
    verify();
  }, [hash, token, setToken, code, isCIAM, isPublic]);

  const contextValue = useMemo(() => ({
    isAuthenticated,
    isLoading,
    setIsAuthenticated,
    token,
    isCIAM,
  }));

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default Auth;
